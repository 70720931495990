<ng-template #skeleton>
  <div class="skeleton" @fadeOut>
    <div>
      <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="text-4 mx-1"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="channel float-end"></ngx-skeleton-loader>
    </div>
    <div class="level">
      <div>
        <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="text-6 mx-1"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="channel float-end"></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="text-7 ms-1"></ngx-skeleton-loader>
      </div>
      <div class="level">
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-6 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-4 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-4 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-4 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-5 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-3 ms-1"></ngx-skeleton-loader>
        </div>
      </div>
      <div>
        <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="text-8 ms-1"></ngx-skeleton-loader>
      </div>
      <div class="level">
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-6 ms-1"></ngx-skeleton-loader>
        </div>
        <div class="level">
          <div>
            <ngx-skeleton-loader class="user"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="text-7 mx-1"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="channel ms-1 float-end"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="channel ms-1 float-end"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="channel ms-1 float-end"></ngx-skeleton-loader>
          </div>
          <div>
            <ngx-skeleton-loader class="user"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="text-4 ms-1"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="channel ms-1 float-end"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="channel ms-1 float-end"></ngx-skeleton-loader>
          </div>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-6 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-5 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-7 ms-1"></ngx-skeleton-loader>
        </div>
        <div>
          <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="text-4 ms-1"></ngx-skeleton-loader>
        </div>
      </div>
      <div>
        <ngx-skeleton-loader class="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="text-4 ms-1"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="channel ms-1 float-end"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #errorTemplate>
  <span *ngIf="error !== false && error?.status === 503; else generalError">Currently unavailable</span>
  <ng-template #generalError>
    <span>There has been a problem loading the data. {{ error | json }}</span>
  </ng-template>
</ng-template>
<div class="card ts-card border-0" [ngClass]="{ online: isLoaded(), offline: !isLoaded() }" data-bs-theme="light">
  <picture>
    <img
      class="card-img-top img-fluid position-relative"
      priority=""
      fill=""
      draggable="false"
      style="height: 100px"
      ngSrc="/assets/img/games/teamspeak.webp"
      [sizes]="cardTopImageSizes"
      alt="Background image of the teamspeak card." />
  </picture>
  <div class="text-white cutout">
    <h2 class="card-title">TeamSpeak 3</h2>
  </div>
  <div class="card-body">
    <div class="card-text">
      <div class="holder row">
        <ng-container
          *ngTemplateOutlet="isLoaded() ? viewer : error !== false ? errorTemplate : skeleton"></ng-container>
        <ng-template #viewer>
          <div @fadeIn>
            <ul
              class="list-unstyled mb-0"
              style="
                font-family: Arial, 'Alatsi', 'Tahoma', sans-serif;
                font-size: 14px;
                line-height: 16px;
                image-rendering: pixelated !important;
              ">
              <li>
                <div class="d-flex justify-content-between" style="height: 16px">
                  <div class="align-self-start">
                    <img
                      class="align-self-start"
                      style="vertical-align: top"
                      ngSrc="assets/img/ts/server.webp"
                      alt="Client"
                      width="16"
                      height="16"
                      disableOptimizedSrcset />
                    <span class="ms-1">{{ server?.name }}</span>
                  </div>
                  <div class="align-self-end d-flex justify-content-start align-items-center" style="gap: 0.125rem">
                    <img
                      *ngIf="server?.iconId"
                      ngSrc="{{ this.getIconUrl(server!.iconId!) }}"
                      alt="Server Icon"
                      width="16"
                      height="16"
                      disableOptimizedSrcset />
                  </div>
                </div>
                <ul class="list-unstyled ms-3">
                  <li style="margin-top: 1px" *ngFor="let channel of server?.channels; trackBy: trackChannel">
                    <ng-container *ngTemplateOutlet="channelTemplate; context: { channel: channel }"></ng-container>
                  </li>
                </ul>

                <ng-template #clientTemplate let-clientAny="client">
                  <div
                    *ngIf="asClient(clientAny) as client"
                    class="d-flex justify-content-between"
                    style="height: 16px">
                    <div class="align-self-start">
                      <img
                        class="align-self-start"
                        ngSrc="assets/img/ts/{{ this.clientIcon(client) }}"
                        alt="Client"
                        width="16"
                        height="16"
                        disableOptimizedSrcset />
                      <span class="ms-1">{{ client.name }}</span>
                    </div>
                    <div class="align-self-end d-flex justify-content-start align-items-center" style="gap: 0.125rem">
                      <img
                        *ngIf="client.channelGroup && client.channelGroup.iconId"
                        alt="Icon for the channel group {{ client.channelGroup.name }}"
                        title="{{ client.channelGroup.name }}"
                        ngSrc="{{ this.getIconUrl(client.channelGroup.iconId) }}"
                        width="16"
                        height="16"
                        disableOptimizedSrcset />
                      <ng-container *ngFor="let sg of client.serverGroups; trackBy: trackGroup">
                        <img
                          *ngIf="sg?.iconId"
                          alt="Icon for the server group {{ sg!.name }}"
                          title="{{ sg!.name }}"
                          ngSrc="{{ this.getIconUrl(sg!.iconId!) }}"
                          width="16"
                          height="16"
                          disableOptimizedSrcset />
                      </ng-container>
                      <img
                        *ngIf="client.clientIcon"
                        alt="Client Icon"
                        title="Client Icon"
                        ngSrc="{{ this.getIconUrl(client.clientIcon) }}"
                        width="16"
                        height="16"
                        disableOptimizedSrcset />
                      <img
                        *ngIf="client.country || 'UNKNOWN' as country"
                        alt="Flag of {{ country }}"
                        title="{{ country }}"
                        ngSrc="{{ this.getFlagIconUrl(country) }}"
                        width="16"
                        height="11"
                        disableOptimizedSrcset />
                    </div>
                  </div>
                </ng-template>
                <ng-template #channelTemplate let-channelAny="channel">
                  <ng-container *ngIf="asChannel(channelAny) as channel">
                    <div class="d-flex justify-content-between" style="height: 16px">
                      <ng-container *ngIf="channel.spacer; else normalChannel">
                        <span *ngIf="channel.spacer.type === 'left'">{{ channel.spacer.text }}</span>
                        <span *ngIf="channel.spacer.type === 'center'" class="w-100 text-center">
                          {{ channel.spacer.text }}
                        </span>
                        <span *ngIf="channel.spacer.type === 'right'" class="w-100 text-end">
                          {{ channel.spacer.text }}
                        </span>
                        <span *ngIf="channel.spacer.type === 'repeat'" class="overflow-hidden">
                          <ng-container *ngIf="channel.spacer.text">
                            <ng-container *ngFor="let _ of [].constructor(100 / (channel.spacer.text?.length || 100))">
                              {{ channel.spacer.text }}
                            </ng-container>
                          </ng-container>
                        </span>
                      </ng-container>
                      <ng-template #normalChannel>
                        <div class="align-self-start">
                          <img
                            class="align-self-start"
                            style="vertical-align: top"
                            ngSrc="assets/img/ts/{{ this.channelIcon(channel) }}"
                            alt="Channel {{ channel.isPasswordProtected ? ' (password protected)' : '' }}"
                            title="Channel {{ channel.isPasswordProtected ? ' (password protected)' : '' }}"
                            height="16"
                            width="16"
                            disableOptimizedSrcset />
                          <span class="ms-1">{{ channel.name }}</span>
                        </div>
                        <div
                          class="align-self-end d-flex justify-content-start align-items-center"
                          style="gap: 0.125rem">
                          <img
                            title="Default channel"
                            *ngIf="channel.isDefault"
                            ngSrc="assets/img/ts/channel-home.webp"
                            width="16"
                            height="16"
                            disableOptimizedSrcset
                            alt="Default channel" />
                          <img
                            *ngIf="channel.isPasswordProtected"
                            ngSrc="assets/img/ts/channel-password-right.webp"
                            width="16"
                            height="16"
                            disableOptimizedSrcset
                            alt="Password protected"
                            title="This channel is password protected." />
                          <img
                            *ngIf="channel.iconId"
                            ngSrc="{{ this.getIconUrl(channel.iconId) }}"
                            width="16"
                            height="16"
                            disableOptimizedSrcset
                            alt="Channel Icon" />
                        </div>
                      </ng-template>
                    </div>

                    <ul class="list-unstyled ms-3">
                      <li style="margin-top: 1px" *ngFor="let client of channel?.clients">
                        <ng-container *ngTemplateOutlet="clientTemplate; context: { client: client }"></ng-container>
                      </li>
                      <li style="margin-top: 1px" *ngFor="let channel of channel?.channels">
                        <ng-container *ngTemplateOutlet="channelTemplate; context: { channel: channel }"></ng-container>
                      </li>
                    </ul>
                  </ng-container>
                </ng-template>
              </li>
            </ul>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
