<div class="alert alert-warning fade show" role="alert" *ngIf="!online$">
  <h4 class="alert-heading">You are offline.</h4>
  You appear to be offline. Please connect to the internet for the latest status.
</div>
<h2 class="d-none">List of Services</h2>
<ngx-masonry #masonry class="row mb-n4 mx-mb-n4" *ngIf="online$" [options]="masonryOptions" [ordered]="true">
  <div class="grid-sizer col-sm-6 col-md-4 col-lg-4 col-xl-3"></div>

  <app-ts-viewer
    ngxMasonryItem
    class="grid-item static col-sm-12 col-md-8 col-lg-4 col-xl-3 mb-4"
    [style.opacity]="firstLayoutCompleted$.getValue() ? 0 : 1"
    (dataChanged$)="isBrowser() && masonry.layout()">
  </app-ts-viewer>

  <app-server
    ngxMasonryItem
    class="grid-item dynamic col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4"
    *ngFor="let entry of servers$ | async | sortServer; trackBy: trackServerItemBy"
    [server]="entry">
  </app-server>
</ngx-masonry>
