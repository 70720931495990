import { animate, group, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  isDevMode,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { OnlineService } from '../../../online.service';
import { findIndex, orderBy } from 'lodash-es';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '../data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, bufferTime, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { runInZone } from '../../../utils/rxjs-zone';
import { GameServer } from '../../model/gameserver/model';

@Component({
  selector: 'app-serverlist',
  templateUrl: './serverlist.component.html',
  styleUrls: ['./serverlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerlistComponent implements OnInit, OnDestroy, AfterViewInit {
  public masonryOptions: NgxMasonryOptions = {
    columnWidth: '.grid-sizer',
    itemSelector: '.grid-item',
    horizontalOrder: false,
    percentPosition: true,
    initLayout: true,
    resize: true,
    animations: {
      show: [
        style({ opacity: 0 }),
        group([
          animate('.3s ease-out', style({ transform: 'translateX(0) translateY(0)' })),
          animate('.3s ease-in', style({ opacity: 1 })),
        ]), // for ts-viewer
      ],
      hide: [
        style({ opacity: '*' }),
        animate('.3s ease-in', style({ opacity: 0 })), // for ts-viewer
      ],
    },
  };

  public servers$: BehaviorSubject<GameServer[]> = new BehaviorSubject<GameServer[]>([]);
  public firstLayoutCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public online$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  @ViewChild('masonry') masonry!: NgxMasonryComponent;

  constructor(
    private readonly destroyRef: DestroyRef,
    protected readonly dataService: DataService,
    private readonly onlineService: OnlineService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: never
  ) {}

  ngOnInit(): void {
    if (this.isBrowser()) {
      this.onlineService.status$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.online$);
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowser()) {
      const serverStore: GameServer[] = [];
      this.zone.runOutsideAngular(() => {
        this.dataService.serverUpdates$
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            bufferTime(10),
            map(function (values: GameServer[]) {
              values.forEach(value => {
                const index = findIndex(serverStore, { id: value.id });
                if (index === -1) {
                  serverStore.push(value);
                } else {
                  serverStore.splice(index, 1, value);
                }
              });
              orderBy(serverStore, ['online', 'type', 'name', 'id'], ['desc', 'asc', 'asc', 'asc']);
              return serverStore;
            }),
            runInZone(this.zone)
          )
          .subscribe(this.servers$);
      });

      this.masonry.layoutComplete
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          take(1),
          map(() => false)
        )
        .subscribe(this.firstLayoutCompleted$);
    }
  }

  ngOnDestroy() {
    isDevMode() && console.log('Destroy Serverlist');
  }

  public trackServerItemBy(_: number, entry: GameServer) {
    return entry.id;
  }

  public isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
}
